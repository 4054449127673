import React from 'react';
import cn from 'classnames';

import componentStyles from './AddressBlurb.module.scss';
import typographyStyles from '../../styles/globals/typography.module.scss';

export interface AddressBlurbProps {
  linkTitle: string;
  url: string;
  street: string;
  city: string;
  country: string;
}

const AddressBlurb: React.SFC<AddressBlurbProps> = ({
  linkTitle,
  url,
  street,
  city,
  country,
}) => {
  return (
    <>
      <a
        className={cn([componentStyles.link, typographyStyles['heading--six']])}
        href={url}
      >
        {linkTitle}
      </a>
      <address
        className={cn([
          componentStyles.address,
          typographyStyles['subheading--three'],
        ])}
      >
        <p>{street}</p>
        <p>{city}</p>
        <p>{country}</p>
      </address>
    </>
  );
};

export default AddressBlurb;
