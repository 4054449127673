import React from 'react';
import cn from 'classnames';
import {Colors, Sizes} from '../../utils/typeDefinitions';

import componentStyles from './Grid.module.scss';
import spacingStyles from '../../styles/globals/spacing.module.scss';
import backgroundStyles from '../../styles/globals/backgrounds.module.scss';

type Count = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

// Interfaces
interface RowProps {
  fullHeight?: boolean;
  fullWidth?: boolean;
  backgroundColor?: Colors;
  desktopPadding?: Sizes;
  mobilePadding?: Sizes;
  background?: boolean;
  section?: boolean;
  footer?: boolean;
  additionalClasses?: string;
  children: React.ReactNode;
}

interface ColProps {
  fullWidth?: boolean;
  mobile?: Count;
  small?: Count;
  medium?: Count;
  large?: Count;
  desktop?: Count;
  wide?: Count;
  offset?: boolean;
  mobileOffset?: Count;
  smallOffset?: Count;
  mediumOffset?: Count;
  largeOffset?: Count;
  desktopOffset?: Count;
  wideOffset?: Count;
  additionalClasses?: string;
  animation?: string;
  animationDelay?: number;
  section?: boolean;
  children: React.ReactNode;
}

const Row: React.SFC<RowProps> = ({
  fullHeight,
  desktopPadding,
  mobilePadding,
  background,
  backgroundColor,
  section,
  fullWidth,
  footer,
  additionalClasses,
  children,
}) => {
  const classes = cn(componentStyles.row, {
    [componentStyles['full-height']]: fullHeight,
    [componentStyles.background]: background,
    [backgroundStyles[`${backgroundColor}`]]: backgroundColor,
    [spacingStyles[`padding-mobile--${mobilePadding}`]]: mobilePadding,
    [spacingStyles[`padding-desktop--${desktopPadding}`]]: desktopPadding,
    [componentStyles['full-width']]: fullWidth,
    [componentStyles.footer]: footer,
    [`${additionalClasses}`]: additionalClasses,
  });

  return section ? (
    <section className={classes}>{children}</section>
  ) : (
    <div className={classes}>{children}</div>
  );
};

export const Col: React.SFC<ColProps> = ({
  fullWidth,
  mobile,
  small,
  medium,
  large,
  desktop,
  wide,
  offset,
  mobileOffset,
  smallOffset,
  mediumOffset,
  largeOffset,
  desktopOffset,
  wideOffset,
  additionalClasses,
  animation,
  animationDelay = 1,
  section,
  children,
}) => {
  const classes = cn(componentStyles.col, {
    [componentStyles['full-width']]: fullWidth,
    [componentStyles[`mobile-${mobile}`]]: mobile,
    [componentStyles[`mobile-${mobile}`]]: mobile,
    [componentStyles[`small-${small}`]]: small,
    [componentStyles[`medium-${medium}`]]: medium,
    [componentStyles[`large-${large}`]]: large,
    [componentStyles[`desktop-${desktop}`]]: desktop,
    [componentStyles[`wide-${wide}`]]: wide,
    [componentStyles.offset]: offset,
    [componentStyles[`offset-mobile-${mobileOffset}`]]: mobileOffset,
    [componentStyles[`offset-small-${smallOffset}`]]: smallOffset,
    [componentStyles[`offset-medium-${mediumOffset}`]]: mediumOffset,
    [componentStyles[`offset-large-${largeOffset}`]]: largeOffset,
    [componentStyles[`offset-desktop-${desktopOffset}`]]: desktopOffset,
    [componentStyles[`offset-wide-${wideOffset}`]]: wideOffset,
    [`${additionalClasses}`]: additionalClasses,
  });

  return section ? (
    <section
      className={classes}
      data-aos={animation}
      data-aos-delay={50 * animationDelay}
    >
      {children}
    </section>
  ) : (
    <div
      className={classes}
      data-aos={animation}
      data-aos-delay={50 * animationDelay}
    >
      {children}
    </div>
  );
};

export default Row;
