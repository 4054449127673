import React from 'react';
import cn from 'classnames';

import PrincipleBlurb, {
  PrincipleBlurbProps,
} from '../../components/PrincipleBlurb';
import HeadlineDescription from '../../components/HeadlineDescription';
import Row, {Col} from '../../components/Grid';

import sectionStyles from './DesignPrinciples.module.scss';
import spacingStyles from '../../styles/globals/spacing.module.scss';

export interface DesignPrinciplesProps {
  title: string;
  bodyParagraphs: string[];
  principlesBlurbs: PrincipleBlurbProps[];
}

const DesignPrinciplesSection: React.SFC<DesignPrinciplesProps> = ({
  title,
  bodyParagraphs,
  principlesBlurbs,
}) => {
  return (
    <Col animation="fade-up">
      <Row desktopPadding="l" mobilePadding="m">
        <Col small={7} desktop={6} wide={5} smallOffset={1} wideOffset={1}>
          <HeadlineDescription title={title} description={bodyParagraphs} />
        </Col>
      </Row>

      <Row section>
        {principlesBlurbs.map((blurb, index) => (
          <Col
            key={index}
            medium={3}
            desktop={2}
            wide={2}
            additionalClasses={cn([
              sectionStyles.blurb,
              spacingStyles['padding-mobile--m'],
              spacingStyles['padding-desktop--l'],
            ])}
            animation="zoom-in"
            animationDelay={index + 1}
          >
            <PrincipleBlurb number={index + 1} caption={blurb.caption} />
          </Col>
        ))}
      </Row>
    </Col>
  );
};

export default DesignPrinciplesSection;
