import React from 'react';
import cn from 'classnames';

import FlexContainer from '../../components/FlexContainer';
import AddressBlurb, {AddressBlurbProps} from '../../components/AddressBlurb';
import {Col} from '../../components/Grid';

import sectionStyles from './JoinTheTeam.module.scss';
import typographyStyles from '../../styles/globals/typography.module.scss';
import spacingStyles from '../../styles/globals/spacing.module.scss';

export interface JoinTheTeamProps {
  title: string;
  bodyParagraphs: string[];
  findYourRoleTitleMobile: string;
  findYourRoleTitleDesktop: string;
  durhamAddress: AddressBlurbProps;
  cvilleAddress: AddressBlurbProps;
  columbusAddress: AddressBlurbProps;
}

const JoinTheTeamSection: React.SFC<JoinTheTeamProps> = ({
  title,
  bodyParagraphs,
  findYourRoleTitleMobile,
  findYourRoleTitleDesktop,
  durhamAddress,
  cvilleAddress,
  columbusAddress,
}) => {
  return (
    <>
      <Col offset large={4} wide={3} wideOffset={1}>
        <h3
          className={cn([
            typographyStyles['heading--four'],
            sectionStyles.title,
          ])}
        >
          {title}
        </h3>

        <p
          className={cn([
            sectionStyles.description,
            spacingStyles['padding-mobile--l'],
          ])}
        >
          {bodyParagraphs}
        </p>
      </Col>

      <Col offset additionalClasses={sectionStyles.mobile}>
        <h4
          className={cn(
            typographyStyles['heading--five'],
            spacingStyles['padding-mobile--m']
          )}
        >
          {findYourRoleTitleMobile}
        </h4>
        <FlexContainer
          alignItems="center"
          direction="column"
          justifyContent="space-around"
          noWrap
        >
          <a
            className={cn([sectionStyles.link, typographyStyles.white])}
            href={cvilleAddress.url}
          >
            Charlottesville
          </a>

          <a
            className={cn([sectionStyles.link, typographyStyles.white])}
            href={columbusAddress.url}
          >
            Columbus
          </a>

          <a
            className={cn([sectionStyles.link, typographyStyles.white])}
            href={durhamAddress.url}
          >
            Durham
          </a>
        </FlexContainer>
      </Col>

      <Col
        large={3}
        largeOffset={1}
        wide={3}
        wideOffset={1}
        additionalClasses={sectionStyles.desktop}
      >
        <FlexContainer direction="column">
          <AddressBlurb
            linkTitle={`${findYourRoleTitleDesktop} Charlottesville`}
            url={cvilleAddress.url}
            street={cvilleAddress.street}
            city={cvilleAddress.city}
            country={cvilleAddress.country}
          />

          <AddressBlurb
            linkTitle={`${findYourRoleTitleDesktop} Columbus`}
            url={columbusAddress.url}
            street={columbusAddress.street}
            city={columbusAddress.city}
            country={columbusAddress.country}
          />

          <AddressBlurb
            linkTitle={`${findYourRoleTitleDesktop} Durham`}
            url={durhamAddress.url}
            street={durhamAddress.street}
            city={durhamAddress.city}
            country={durhamAddress.country}
          />
        </FlexContainer>
      </Col>
    </>
  );
};

export default JoinTheTeamSection;
