import React from 'react';
import {Colors} from '../../utils/typeDefinitions';
import cn from 'classnames';

import componentStyles from './BeliefCard.module.scss';
import backgroundStyles from '../../styles/globals/backgrounds.module.scss';
import typographyStyles from '../../styles/globals/typography.module.scss';

// Desktop Images
import InclusiveImage from '../../assets/images/beliefs/inclusive.svg';
import CollaborativeImage from '../../assets/images/beliefs/collaborative.svg';
import InformedImage from '../../assets/images/beliefs/informed.svg';

type BeliefType = 'collaborative' | 'inclusive' | 'informed';

export interface BeliefCardProps {
  belief: BeliefType;
  rightImage?: boolean;
  caption: string;
  backgroundColor?: Colors;
}

const BeliefCard: React.SFC<BeliefCardProps> = ({
  belief,
  caption,
  backgroundColor = 'greenBlue',
  rightImage,
}) => {
  const imageClasses = cn(componentStyles.image, {
    [componentStyles.right]: rightImage,
  });

  const selectImage = (belief: BeliefType) => {
    switch (belief) {
      case 'inclusive':
        return InclusiveImage;
      case 'collaborative':
        return CollaborativeImage;
      case 'informed':
        return InformedImage;
      default:
        return '';
    }
  };

  return (
    <>
      <figure
        className={cn(
          componentStyles.figure,
          backgroundStyles[`${backgroundColor}`]
        )}
      >
        <img className={imageClasses} src={selectImage(belief)} alt="" />
        <figcaption
          className={cn(typographyStyles['list--two'], componentStyles.caption)}
        >
          {caption}
        </figcaption>
      </figure>

      <div
        className={cn(
          componentStyles.container,
          componentStyles[`container--${belief}`]
        )}
      >
        <p
          className={cn(
            typographyStyles['list--two'],
            componentStyles.caption,
            componentStyles[`caption--${belief}`]
          )}
        >
          {caption}
        </p>
      </div>
    </>
  );
};

export default BeliefCard;
