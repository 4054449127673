import React from 'react';
import cn from 'classnames';

import CultureCallout, {
  CultureCalloutProps,
} from '../../components/CultureCallout';
import Row, {Col} from '../../components/Grid';

import sectionStyles from './DesignCulture.module.scss';
import typographyStyles from '../../styles/globals/typography.module.scss';
import spacingStyles from '../../styles/globals/spacing.module.scss';

export interface DesignCultureProps {
  title: string;
  culturePoints: CultureCalloutProps[];
}

const DesignCultureSection: React.SFC<DesignCultureProps> = ({
  title,
  culturePoints,
}) => {
  const heroPoint = culturePoints.shift();

  return (
    <Col animation="fade-up">
      <Row>
        <Col offset wideOffset={1}>
          <h3
            className={cn(
              typographyStyles['heading--four'],
              spacingStyles.offset
            )}
          >
            {title}
          </h3>
        </Col>
      </Row>

      <Row>
        <Col>
          {heroPoint && (
            <CultureCallout
              image={heroPoint.image}
              altText={heroPoint.altText}
              title={heroPoint.title}
              description={heroPoint.description}
              large
            />
          )}
        </Col>
      </Row>

      <Row additionalClasses={sectionStyles.container}>
        {culturePoints.map((point, index) => (
          <Col
            key={point.image}
            small={2}
            smallOffset={2}
            desktop={1}
            desktopOffset={1}
            wide={1}
            wideOffset={1}
            additionalClasses={sectionStyles.item}
            animation="zoom-in"
            animationDelay={index + 1}
          >
            <CultureCallout
              index={index}
              image={point.image}
              altText={point.altText}
              title={point.title}
            />
          </Col>
        ))}
      </Row>
    </Col>
  );
};

export default DesignCultureSection;
