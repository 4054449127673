import React from 'react';
import cn from 'classnames';

import Row, {Col} from '../../components/Grid';

import sectionStyles from './ThreeOffices.module.scss';
import typographyStyles from '../../styles/globals/typography.module.scss';
import spacingStyles from '../../styles/globals/spacing.module.scss';

export interface ThreeOfficesProps {
  title: string;
  cvilleCaption: string;
  durhamCaption: string;
  columbusCaption: string;
}

const ThreeOfficesSection: React.SFC<ThreeOfficesProps> = ({
  title,
  cvilleCaption,
  durhamCaption,
  columbusCaption,
}) => {
  return (
    <Col animation="fade-up" fullWidth>
      {/* Bad way to add a spacer, don't do this normally... */}
      <div
        className={cn(
          spacingStyles.spacer,
          spacingStyles['padding-mobile--section-l'],
          spacingStyles['padding-desktop--xl']
        )}
      />

      <Row>
        <Col offset smallOffset={2} wideOffset={1}>
          <h3
            className={cn([
              typographyStyles['heading--four'],
              sectionStyles.title,
            ])}
          >
            {title}
          </h3>
        </Col>
      </Row>

      <Row>
        <Col
          additionalClasses={cn([
            sectionStyles.graphic,
            typographyStyles.white,
          ])}
        >
          <span
            role="img"
            aria-label="Three photos of WillowTree’s three main offices, left: Charlottesville common area with spiral staircase and two employees working. Middle: Durham common area, open and light collaboration space. Right: Columbus common area with two employees working. "
          />

          <Row additionalClasses={sectionStyles['text-container']}>
            <Col additionalClasses={sectionStyles['graphic-text']}>
              <p>Durham, NC</p>
              <p className={typographyStyles['heading--five']}>
                {durhamCaption}
              </p>
            </Col>

            <Col additionalClasses={sectionStyles['graphic-text']}>
              <p>Charlottesville, VA</p>
              <p className={typographyStyles['heading--five']}>
                {cvilleCaption}
              </p>
            </Col>
            <Col additionalClasses={sectionStyles['graphic-text']}>
              <p>Columbus, OH</p>
              <p className={typographyStyles['heading--five']}>
                {columbusCaption}
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default ThreeOfficesSection;
