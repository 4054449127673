import React from 'react';
import cn from 'classnames';

import Blockquote, {BlockQuoteProps} from '../../components/Blockquote';
import HeadlineDescription from '../../components/HeadlineDescription';
import Row, {Col} from '../../components/Grid';
import LottieAnimation from '../../components/LottieAnimation';

import spacingStyles from '../../styles/globals/spacing.module.scss';

export interface MentorShipProps {
  title: string;
  prequoteParagraphs: string[];
  mentorQuotes: BlockQuoteProps[];
  postquoteParagraphs: string[];
}

const MentorshipSection: React.SFC<MentorShipProps> = ({
  title,
  prequoteParagraphs,
  mentorQuotes,
  postquoteParagraphs,
}) => {
  return (
    <Col animation="fade-up">
      <Row mobilePadding="l" desktopPadding="l" section>
        <Col
          small={7}
          smallOffset={1}
          medium={7}
          large={7}
          desktop={6}
          wide={5}
          wideOffset={1}
        >
          <HeadlineDescription title={title} description={prequoteParagraphs} />
        </Col>
      </Row>

      <Row mobilePadding="l" desktopPadding="l" section>
        <Col small={7} desktop={6} wide={5} smallOffset={1} wideOffset={2}>
          {mentorQuotes.map((quote, index) => (
            <Blockquote key={index} quote={quote.quote} author={quote.author} />
          ))}
        </Col>
      </Row>

      <Row mobilePadding="l" desktopPadding="l">
        <Col
          offset
          small={6}
          smallOffset={2}
          large={6}
          desktop={5}
          wide={4}
          wideOffset={2}
        >
          {postquoteParagraphs.map((paragraph, index) => (
            <p
              key={index}
              className={cn(spacingStyles.offset, spacingStyles['text-width'])}
            >
              {paragraph}
            </p>
          ))}
        </Col>
      </Row>

      <Row>
        <Col small={8} additionalClasses="mobile">
          <LottieAnimation lottie="Mentorship-Mobile" />
        </Col>

        <Col small={8} wide={8} additionalClasses="desktop">
          <LottieAnimation lottie="Mentorship-Desktop" />
        </Col>
      </Row>
    </Col>
  );
};

export default MentorshipSection;
