import React from 'react';
import cn from 'classnames';
import {Colors, HeadingSizes, Sizes} from '../../utils/typeDefinitions';
import Row, {Col} from '../Grid';

import typographyStyles from '../../styles/globals/typography.module.scss';

interface HeadlineDescription {
  headlineColor?: Colors;
  title: string;
  description: string[];
  imageClassName?: string;
  headlineSize?: HeadingSizes;
  offset?: boolean;
  mobileSpace?: Sizes;
  desktopSpace?: Sizes;
}

const HeadlineDescription: React.SFC<HeadlineDescription> = ({
  headlineColor = 'black',
  title,
  description,
  headlineSize = 'four',
}) => {
  const headlineClasses = cn({
    [typographyStyles[`heading--${headlineSize}`]]: headlineSize,
    [typographyStyles[`${headlineColor}`]]: headlineColor,
  });

  return (
    <Row>
      <Col>
        {headlineSize ? (
          <h3 className={headlineClasses}>{title}</h3>
        ) : (
          <h2 className={headlineClasses}>{title}</h2>
        )}
      </Col>

      <Col offset wideOffset={1}>
        {description.map((paragraph, index) => (
          <p key={index}>{paragraph}</p>
        ))}
      </Col>
    </Row>
  );
};

export default HeadlineDescription;
