import React from 'react';
import ReactPlayer from 'react-player';

import componentStyles from './Vimeo.module.scss';

import thumbnail from '../../assets/images/VideoThumbnail.jpg';

const Vimeo = () => {
  return (
    <ReactPlayer
      url="https://vimeo.com/331467285"
      controls
      width="100%"
      height="100%"
      className={componentStyles.player}
      light={thumbnail}
    />
  );
};

export default Vimeo;
