import React from 'react';
import ProcessStep, {ProcessStepProps} from '../../components/ProcessStep';
import HeadlineDescription from '../../components/HeadlineDescription';
import Row, {Col} from '../../components/Grid';

export interface DesignProcessProps {
  title: string;
  bodyParagraphs: string[];
  steps: ProcessStepProps[];
}

const DesignProcessSection: React.SFC<DesignProcessProps> = ({
  title,
  bodyParagraphs,
  steps,
}) => {
  return (
    <Col animation="fade-up">
      <Row mobilePadding="section-s" desktopPadding="l" section>
        <Col small={7} smallOffset={1} desktop={6} wide={5} wideOffset={1}>
          <HeadlineDescription title={title} description={bodyParagraphs} />
        </Col>
      </Row>

      <Row>
        {steps.map((step, index) => (
          <ProcessStep
            key={step.image}
            image={step.image}
            title={step.title}
            description={step.description}
            index={index}
          />
        ))}
      </Row>
    </Col>
  );
};

export default DesignProcessSection;
