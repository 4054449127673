import React from 'react';
import cn from 'classnames';

import BackgroundVideo from '../../components/BackgroundVideo';
import {Col} from '../../components/Grid';

import sectionStyles from './WhereWeWork.module.scss';
import typographyStyles from '../../styles/globals/typography.module.scss';

export interface WhereWeWorkProps {
  title: string;
}

const WhereWeWorkSection: React.SFC<WhereWeWorkProps> = ({title}) => {
  return (
    <Col small={8} wide={8} fullWidth>
      <BackgroundVideo />
      <h2
        className={cn(
          typographyStyles.white,
          typographyStyles['heading--two'],
          sectionStyles.title
        )}
      >
        {title}
      </h2>
    </Col>
  );
};

export default WhereWeWorkSection;
