import React from 'react';
import cn from 'classnames';

import Row, {Col} from '../../components/Grid';
import LottieAnimation from '../../components/LottieAnimation';

import sectionStyles from './WillowTreeIsADestinationJob.module.scss';
import typographyStyles from '../../styles/globals/typography.module.scss';

export interface WillowTreeIsADestinationJobProps {
  title: string;
  bodyParagraphs: string[];
}

const WillowTreeIsADestinationJobSection: React.SFC<
  WillowTreeIsADestinationJobProps
> = ({title, bodyParagraphs}) => {
  return (
    <Col animation="fade-up">
      <Row>
        <Col smallOffset={1} wideOffset={1}>
          <h3
            className={cn([
              typographyStyles['heading--four'],
              sectionStyles.title,
            ])}
          >
            {title}
          </h3>
        </Col>
      </Row>

      <Row>
        <Col small={8} additionalClasses="mobile" fullWidth>
          <LottieAnimation lottie="Map-Mobile" loop={false} />
        </Col>

        <Col small={8} wide={8} additionalClasses="desktop">
          <LottieAnimation lottie="Map-Desktop" />
        </Col>
        <Col
          offset
          small={6}
          smallOffset={2}
          additionalClasses={sectionStyles.body}
        >
          <p>{bodyParagraphs}</p>
        </Col>
      </Row>
    </Col>
  );
};

export default WillowTreeIsADestinationJobSection;
