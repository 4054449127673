import React from 'react';
import cn from 'classnames';

import {Col} from '../Grid';

import componentStyles from './ProcessStep.module.scss';
import spacingStyles from '../../styles/globals/spacing.module.scss';
import typographyStyles from '../../styles/globals/typography.module.scss';

import DiscoveryImage from '../../assets/images/process/discovery.svg';
import ConceptImage from '../../assets/images/process/concept.svg';
import SprintImage from '../../assets/images/process/sprint.svg';

export interface ProcessStepProps {
  image: string;
  title: string;
  description: string;
  index: number;
}

const ProcessStep: React.SFC<ProcessStepProps> = ({
  image,
  title,
  description,
  index,
}) => {
  const selectImage = (image: string) => {
    switch (image) {
      case 'discovery':
        return DiscoveryImage;
      case 'concept':
        return ConceptImage;
      case 'sprint':
        return SprintImage;
      default:
        return '';
    }
  };

  return (
    <Col
      offset
      small={6}
      smallOffset={2}
      large={2}
      largeOffset={1}
      wide={2}
      wideOffset={1}
      additionalClasses={componentStyles.step}
      animation="fade-up"
      animationDelay={index}
    >
      <img
        className={cn(componentStyles.image, spacingStyles['padding--s'])}
        alt=""
        src={selectImage(image)}
      />
      <h4 className={typographyStyles['heading--six']}>{title}</h4>
      <p>{description}</p>
    </Col>
  );
};

export default ProcessStep;
