import React, {useEffect} from 'react';
import data from './utils/pageData';
import AOS from 'aos';

import Row from './components/Grid';
import DesignDNASection from './sections/DesignDNA';
import VisionSection from './sections/Vision';
import HowWeAreOrganizedSection from './sections/HowWeAreOrganized';
import MentorshipSection from './sections/Mentorship';
import WhatIsAProductDesignerSection from './sections/WhatIsAProductDesigner';
import GrowthSection from './sections/Growth';
import HowWeWorkSection from './sections/HowWeWork';
import DesignPrinciplesSection from './sections/DesignPrinciples';
import DesignCultureSection from './sections/DesignCulture';
import WhereWeWorkSection from './sections/WhereWeWork';
import ThreeOfficesSection from './sections/ThreeOffices';
import WillowTreeIsADestinationJobSection from './sections/WillowTreeIsADestinationJob';
import DemoReelSection from './sections/DemoReel';
import JoinTheTeamSection from './sections/JoinTheTeam';

import gridStyles from './components/Grid/Grid.module.scss';
import DesignProcessSection from './sections/DesignProcess';
import ProductDesignClientSection from './sections/ProductDesignClients';

function App() {
  useEffect(() => {
    setTimeout(() => {
      AOS.init({
        once: true,
        offset: 100,
      });
    }, 500);
  });

  return (
    <>
      <main>
        <Row fullHeight section>
          <DesignDNASection {...data.designDNASection} />
        </Row>
        <Row
          mobilePadding="section-l"
          desktopPadding="l"
          section
          additionalClasses="z-3"
        >
          <VisionSection {...data.visionSection} />
        </Row>
        <Row mobilePadding="section-l" desktopPadding="xl" section>
          <ProductDesignClientSection {...data.productDesignClientsSection} />
        </Row>
        <Row mobilePadding="section-l" desktopPadding="l" section>
          <HowWeAreOrganizedSection {...data.howWeAreOrganizedSection} />
        </Row>
        <Row mobilePadding="section-l" desktopPadding="xl" section>
          <MentorshipSection {...data.mentorshipSection} />
        </Row>
        <Row mobilePadding="section-l" desktopPadding="xl" section>
          <WhatIsAProductDesignerSection
            {...data.whatIsAProductDesignerSection}
          />
        </Row>
        <Row mobilePadding="section-l" desktopPadding="section-l" section>
          <GrowthSection {...data.growthSection} />
        </Row>
        <Row mobilePadding="section-s" desktopPadding="xl" section>
          <HowWeWorkSection {...data.howWeWorkSection} />
        </Row>
        <Row mobilePadding="section-l" desktopPadding="xl" section>
          <DesignPrinciplesSection {...data.designPrinciplesSection} />
        </Row>
        <Row mobilePadding="section-l" desktopPadding="xl" section>
          <DesignProcessSection {...data.designProcessSection} />
        </Row>
        <Row mobilePadding="section-l" desktopPadding="section-l" section>
          <DesignCultureSection {...data.designCultureSection} />
        </Row>
        <Row background mobilePadding="none" desktopPadding="none" section>
          <WhereWeWorkSection {...data.whereWeWorkSection} />
        </Row>
        <Row mobilePadding="section-l" desktopPadding="xl" section fullWidth>
          <ThreeOfficesSection {...data.threeOfficesSection} />
        </Row>
        <Row mobilePadding="section-l" desktopPadding="section-l" section>
          <WillowTreeIsADestinationJobSection
            {...data.willowTreeIsADestinationJobSection}
          />
        </Row>
        <Row
          desktopPadding="xl"
          section
          additionalClasses={gridStyles['medium-full']}
        >
          <DemoReelSection {...data.demoReelSection} />
        </Row>
      </main>
      <footer>
        <Row
          mobilePadding="section-l"
          desktopPadding="xl"
          fullWidth
          section
          footer
        >
          <JoinTheTeamSection {...data.joinTheTeamSection} />
        </Row>
      </footer>
    </>
  );
}

export default App;
