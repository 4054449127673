import React from 'react';

import ImageBanner, {ImageBannerProps} from '../../components/ImageBanner';
import Row, {Col} from '../../components/Grid';

import sectionStyles from './Growth.module.scss';
import typographyStyles from '../../styles/globals/typography.module.scss';
import spacingStyles from '../../styles/globals/spacing.module.scss';

export interface GrowthSectionProps {
  title: string;
  image: ImageBannerProps;
  bodyParagraphs: string[];
}

const GrowthSection: React.SFC<GrowthSectionProps> = ({
  title,
  image,
  bodyParagraphs,
}) => {
  return (
    <Col animation="fade-up" fullWidth>
      <Row>
        <Col smallOffset={1} wideOffset={1}>
          <h3 className={typographyStyles[`heading--three`]}>{title}</h3>
        </Col>
      </Row>
      <Row additionalClasses={sectionStyles['image-container']}>
        <Col smallOffset={1} wide={7} fullWidth>
          <ImageBanner
            className={sectionStyles.image}
            image={image.image}
            type={image.type}
            altText={image.altText}
            swapSize="1023px"
          />
        </Col>
      </Row>
      <Row>
        <Col
          offset
          small={6}
          smallOffset={2}
          desktop={5}
          wide={4}
          wideOffset={2}
        >
          {bodyParagraphs.map((paragraph, index) => (
            <p key={index} className={spacingStyles.offset}>
              {paragraph}
            </p>
          ))}
        </Col>
      </Row>
    </Col>
  );
};

export default GrowthSection;
