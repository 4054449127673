import React from 'react';
import '@lottiefiles/lottie-player';

interface AnimationProps {
  loop?: boolean;
  lottie: string;
}

const LottieAnimation: React.SFC<AnimationProps> = ({loop = true, lottie}) => {
  return loop ? (
    // prettier-ignore
    // @ts-ignore
    <lottie-player autoplay loop mode="normal" src={process.env.PUBLIC_URL + `/lotties/${lottie}.json`} style={{width: '100%'}}></lottie-player>
  ) : (
    // prettier-ignore
    // @ts-ignore
    // The mobile map should only play one time, and loop was not working properly as a conditional...
    <lottie-player autoplay count={1} mode="normal" src={process.env.PUBLIC_URL + `/lotties/${lottie}.json`} style={{width: '100%'}}></lottie-player>
  );
};

export default LottieAnimation;
