import React from 'react';
import cn from 'classnames';

import componentStyles from './FlexContainer.module.scss';

// Locking direction/alignment options
type Direction = 'row' | 'column';
type Alignment =
  | 'start'
  | 'center'
  | 'end'
  | 'space-between'
  | 'space-around'
  | 'space-evenly';

// Prop interfaces
interface FlexContainerProps {
  direction?: Direction;
  justifyContent?: Alignment;
  alignItems?: Alignment;
  noWrap?: boolean;
  additionalClasses?: string;
  children: React.ReactNode;
}

// Default file export of Flex Container
const FlexContainer: React.SFC<FlexContainerProps> = ({
  direction,
  justifyContent,
  alignItems,
  noWrap,
  additionalClasses,
  children,
}) => {
  const classes = cn(componentStyles.base, {
    [componentStyles[`justify--${justifyContent}`]]: justifyContent,
    [componentStyles[`align--${alignItems}`]]: alignItems,
    [componentStyles[`${direction}`]]: direction,
    [componentStyles['no-wrap']]: noWrap,
    [`${additionalClasses}`]: additionalClasses,
  });

  return <div className={classes}>{children}</div>;
};

export default FlexContainer;
