import React from 'react';
import HeadlineDescription from '../../components/HeadlineDescription';
import {Colors} from '../../utils/typeDefinitions';
import {Col} from '../../components/Grid';

export interface HowWeWorkProps {
  title: string;
  titleColor: Colors;
  bodyParagraphs: string[];
}

const HowWeWorkSection: React.SFC<HowWeWorkProps> = ({
  title,
  titleColor,
  bodyParagraphs,
}) => {
  return (
    <Col animation="fade-up" small={8} desktop={7} wide={7}>
      <HeadlineDescription
        headlineColor={titleColor}
        headlineSize="two"
        title={title}
        description={bodyParagraphs}
      />
    </Col>
  );
};

export default HowWeWorkSection;
