import React from 'react';

import componentStyles from './BackgroundVideo.module.scss';

const BackgroundVideo = () => {
  return (
    <div className={componentStyles.wrapper}>
      <iframe
        title="Where We Work"
        src="https://player.vimeo.com/video/351396283?autoplay=1&loop=1&background=1"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
        className={componentStyles.video}
      ></iframe>
    </div>
  );
};

export default BackgroundVideo;
