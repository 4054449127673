import React from 'react';
import cn from 'classnames';

import Row, {Col} from '../Grid';

import componentStyles from './CultureCallout.module.scss';
import typographyStyles from '../../styles/globals/typography.module.scss';
import spacingStyles from '../../styles/globals/spacing.module.scss';

import BreakfastImage from '../../assets/images/culture/breakfast.svg';
import ChatBubblesImage from '../../assets/images/culture/chat-bubbles.svg';
import PencilRuleImage from '../../assets/images/culture/pencil-rule.svg';
import PlaneImage from '../../assets/images/culture/plane.svg';
import RatioImage from '../../assets/images/culture/ratio.svg';
import ScalesImage from '../../assets/images/culture/scales.svg';
import TargetImage from '../../assets/images/culture/target.svg';

export interface CultureCalloutProps {
  index?: number;
  image: string;
  altText: string;
  title: string;
  description?: string;
  large?: boolean;
}

const CultureCallout: React.SFC<CultureCalloutProps> = ({
  index = 0,
  image,
  altText,
  title,
  description,
  large,
}) => {
  const isReverse = index % 2 === 0;

  const smallHeadlineAlignment = isReverse
    ? componentStyles['small-header--right']
    : componentStyles['small-header--left'];

  const smallRowClasses = cn(componentStyles.small, {
    [componentStyles.reverse]: !isReverse,
  });

  const selectImage = (culture: string) => {
    switch (culture) {
      case 'breakfast':
        return BreakfastImage;
      case 'chat-bubbles':
        return ChatBubblesImage;
      case 'pencil-rule':
        return PencilRuleImage;
      case 'plane':
        return PlaneImage;
      case 'ratio':
        return RatioImage;
      case 'scales':
        return ScalesImage;
      case 'target':
        return TargetImage;
      default:
        return '';
    }
  };

  return (
    <>
      {large && (
        <Row
          additionalClasses={componentStyles.hero}
          mobilePadding="l"
          desktopPadding="m"
        >
          <Col
            small={2}
            wide={2}
            additionalClasses={componentStyles['figure--large']}
            animation="zoom-in"
          >
            <figure className={cn(componentStyles.figure)}>
              <img
                className={componentStyles['hero-image']}
                src={selectImage(image)}
                alt={altText}
              />
            </figure>
          </Col>

          <Col
            offset
            small={5}
            desktop={4}
            wide={4}
            additionalClasses={componentStyles['hero-content--large']}
          >
            <div className={componentStyles['hero-content']}>
              <h4
                className={cn(
                  typographyStyles['subheading--one'],
                  componentStyles['hero-header']
                )}
              >
                {title}
              </h4>
              <p className={spacingStyles['text-width']}>{description}</p>
            </div>
          </Col>
        </Row>
      )}

      {!large && (
        <Row additionalClasses={smallRowClasses}>
          <Col small={2} additionalClasses={componentStyles.left}>
            <figure>
              <img
                className={componentStyles.figure}
                src={selectImage(image)}
                alt={altText}
              />
            </figure>
          </Col>

          <Col
            small={2}
            additionalClasses={cn(
              componentStyles['small-header'],
              smallHeadlineAlignment
            )}
          >
            <h4 className={cn(typographyStyles['list--two'])}>{title}</h4>
          </Col>
        </Row>
      )}
    </>
  );
};

export default CultureCallout;
