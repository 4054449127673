import React from 'react';
import {Picture} from 'react-responsive-picture';

export interface ImageBannerProps {
  image: string;
  type?: string;
  altText?: string;
  swapSize?: string;
  className?: string;
}

const ImageBanner: React.SFC<ImageBannerProps> = ({
  image,
  type = 'png',
  altText,
  className,
  swapSize = '768px',
}) => {
  altText = altText ? altText : '';

  return (
    <Picture
      className={className}
      sources={[
        {
          srcSet: process.env.PUBLIC_URL + `/images/${image}-mobile.${type}`,
          media: `(max-width: ${swapSize})`,
        },
        {
          srcSet: process.env.PUBLIC_URL + `/images/${image}-desktop.${type}`,
        },
      ]}
      src={process.env.PUBLIC_URL + `/images/${image}-desktop.${type}`}
      alt={altText}
    />
  );
};

export default ImageBanner;
