import React from 'react';
import HeadlineDescription from '../../components/HeadlineDescription';
import EmployeeCallout, {
  EmployeeCalloutProps,
} from '../../components/EmployeeCallout';
import Row, {Col} from '../../components/Grid';

import sectionStyles from './HowWeAreOrganized.module.scss';

export interface HowWeAreOrganizedProps {
  title: string;
  bodyParagraphs: string[];
  employeeCallouts: EmployeeCalloutProps[];
}

const HowWeAreOrganizedSection: React.SFC<HowWeAreOrganizedProps> = ({
  title,
  bodyParagraphs,
  employeeCallouts,
}) => {
  return (
    <Col animation="fade-up">
      <Row mobilePadding="section-s" desktopPadding="l" section>
        <Col
          small={7}
          smallOffset={1}
          medium={7}
          large={6}
          desktop={6}
          wide={5}
          wideOffset={1}
        >
          <HeadlineDescription title={title} description={bodyParagraphs} />
        </Col>
      </Row>
      <Row additionalClasses={sectionStyles.callouts} section>
        {employeeCallouts.map((employee, index) => (
          <Col
            key={index}
            medium={3}
            wide={3}
            wideOffset={1}
            additionalClasses={sectionStyles['callout']}
            animation="zoom-in"
            animationDelay={1.5}
          >
            <EmployeeCallout
              image={employee.image}
              altText={employee.altText}
              name={employee.name}
              position={employee.position}
            ></EmployeeCallout>
          </Col>
        ))}
      </Row>
    </Col>
  );
};

export default HowWeAreOrganizedSection;
