import {DesignDNAProps} from '../sections/DesignDNA';
import {VisionProps} from '../sections/Vision';
import {HowWeAreOrganizedProps} from '../sections/HowWeAreOrganized';
import {MentorShipProps} from '../sections/Mentorship';
import {WhatIsAProductDesignerProps} from '../sections/WhatIsAProductDesigner';
import {GrowthSectionProps} from '../sections/Growth';
import {DesignCultureProps} from '../sections/DesignCulture';
import {HowWeWorkProps} from '../sections/HowWeWork';
import {DesignPrinciplesProps} from '../sections/DesignPrinciples';
import {ThreeOfficesProps} from '../sections/ThreeOffices';
import {WillowTreeIsADestinationJobProps} from '../sections/WillowTreeIsADestinationJob';
import {WhereWeWorkProps} from '../sections/WhereWeWork';
import {DemoReelProps} from '../sections/DemoReel';
import {JoinTheTeamProps} from '../sections/JoinTheTeam';
import {DesignProcessProps} from '../sections/DesignProcess';
import {ProductDesignClientSectionProps} from '../sections/ProductDesignClients';

export default {
  // Design DNA Section
  designDNASection: {
    willowTreeLogoAlt: 'Willowtree, LLC',
    caption: 'our process for great products + culture',
  } as DesignDNAProps,

  // Vision Section
  visionSection: {
    title: 'Vision',
    titleColor: 'cobalt',
    bodyParagraphs: [
      'Designers at WillowTree aren’t here to push pixels and call it a day—we’re here to create the best work of our careers, working in close collaboration with world-class clients to deliver best-in-industry digital products we can all be proud of.',
      'We study modern visual and product design, drawing on its history to push our work forward.',
    ],
    beliefs: {
      title: 'We believe that world-class product design is',
      imageCards: [
        {
          belief: 'inclusive',
          caption: 'inclusive of all users',
        },
        {
          belief: 'collaborative',
          caption: 'made in collaboration rather than isolation',
        },
        {
          belief: 'informed',
          caption:
            'continually informed - and reformed - by the best available research',
        },
      ],
    },
  } as VisionProps,

  // How We're Organized Section
  howWeAreOrganizedSection: {
    title: 'How We’re Organized',
    bodyParagraphs: [
      'WillowTree is an end-to-end digital product partner for some of the world’s leading brands. We see our clients’ digital products through from ideation through development and growth, and design informs every step of the process along the way.',
      'Building a truly excellent design practice requires buy-in at the highest levels of your organization. At WillowTree, design has seats at the executive level and plays a vital role in the overall continued growth and direction of the company.',
    ],
    employeeCallouts: [
      {
        image: 'paul',
        altText: 'Headshot of Paul Giacherio',
        name: 'Paul Giacherio',
        position: 'VP & Design Director',
      },
      {
        image: 'julia',
        altText: 'Headshot of Julia Swenson',
        name: 'Julia Swenson',
        position: 'VP & Design Director',
      },
      {
        image: 'ben',
        altText: 'Headshot of Ben LaPlaca',
        name: 'Ben LaPlaca',
        position: 'VP & Design Director',
      },
      {
        image: 'blake',
        altText: 'Headshot of Blake Sirach',
        name: 'Blake Sirach',
        position: 'Chief Product Officer',
      },
    ],
  } as HowWeAreOrganizedProps,

  // Mentorship Section
  mentorshipSection: {
    title: 'Mentorship',
    prequoteParagraphs: [
      "Every designer at WillowTree has a long-term mentor to help them grow and navigate everything from tricky layouts to client interactions. You'll meet 1:1 with your mentor several times a month to discuss your goals and work together to achieve them.",
    ],
    mentorQuotes: [
      {
        quote:
          'The opportunity to work with individuals that are invested in helping me expand my talents and succeed has been hands-down the most positive and heartwarming experience I’ve had working in the industry.',
        author: {
          image: 'jose',
          altText: 'Headshot of Jose Mireles',
          name: 'Jose Mireles',
          position: 'Product Designer',
        },
      },
    ],
    postquoteParagraphs: [
      "We think it is important to set our designers up with full autonomy over their client work, but no designer is an island. You'll have access to peers with the expertise you are looking for, even if it's simply having that all-important second pair of eyes on a piece of work.",
    ],
  } as MentorShipProps,

  // What Is A Product Designer Section
  whatIsAProductDesignerSection: {
    title: 'What is a Product Designer?',
    bodyParagraphs: [
      "WillowTree Product Designers are responsible for visual, UX, motion, and product decisions. We hire folks who are really good in all of these capacities and particularly killer in at least one of them. Knowledge share and collaboration are central to our workflow, so everyone gets a chance to learn from each others' expertise.",
    ],
    graphic: [
      {
        image: 'light-bulbs',
      },
      {
        image: 'magnifying-glass',
      },
      {
        image: 'windows',
      },
      {
        image: 'hand',
      },
      {
        image: 'brain',
      },
      {
        image: 'vector',
      },
      {
        image: 'play',
      },
      {
        image: 'pen',
      },
    ],
  } as WhatIsAProductDesignerProps,

  // Growth Section
  growthSection: {
    title: 'Growth',
    image: {
      image: 'growth',
      type: 'svg',
      altText:
        'Career Path for Designers follows the flow of Designer to Product Designer to Senior Product Designer to Principal Product Designer to Director of Product Design',
    },
    bodyParagraphs: [
      "You've got goals for your career, and we're serious about helping you achieve them. We've got clear rubrics for all designer levels and promote based on competency, not politics. Also, we know you've got other ambitions than simply moving up in the ranks. Your design mentor will help you identify and meet these personalized growth goals.",
    ],
  } as GrowthSectionProps,

  // How We Work Section
  howWeWorkSection: {
    title: 'How We Work',
    titleColor: 'cobalt',
    bodyParagraphs: [
      "We're here to make each other better. We believe that the best work is achieved when egos are checked at the door so feedback can be thoughtfully given and gracefully received. We believe that a wide set of perspectives is vital for a thriving creative culture, because diverse teams make stronger products.",
    ],
  } as HowWeWorkProps,

  // Design Principles Section
  designPrinciplesSection: {
    title: 'Design Principles',
    bodyParagraphs: [
      'We ground even our most progressive, experimental work for emerging technologies in classic product design principles and best practices. The WillowTree Design Principles will look familiar to a seasoned designer—though we add a few twists:',
    ],
    principlesBlurbs: [
      {
        caption: 'Is the design innovative?',
      },
      {
        caption: 'Is the visual design conceptually sound?',
      },
      {
        caption: 'Is the design "timeless"?',
      },
      {
        caption:
          "Is the visual design unique and distinctive to the client's brand?",
      },
      {
        caption: 'Is the visual design system lightweight and cohesive?',
      },
    ],
  } as DesignPrinciplesProps,

  // Design Process Section
  designProcessSection: {
    title: 'Design Process',
    bodyParagraphs: [
      "From a high level, we work through three phases. Discovery, where we kick off the project and learn about our client's business objectives. Concept, where we find our visual, content, and motion directions through ideating. Feature Design Sprints, where we do the meat of the work like visual design, prototyping, user testing, and asset development.",
    ],
    steps: [
      {
        image: 'discovery',
        title: 'Discovery',
        description: 'Understanding your business objectives, and user mindset',
      },
      {
        image: 'concept',
        title: 'Concept',
        description:
          'Establishing a branded vision & laying the foundation for product architecture',
      },
      {
        image: 'sprint',
        title: 'Feature Design Sprints',
        description:
          'Continuous cycles of ideating, designing, prototyping, evaluating, and iterating',
      },
    ],
  } as DesignProcessProps,

  // Product Design Clients
  productDesignClientsSection: {
    title: 'Product Design Clients',
    clients: [
      {
        image: 'synchrony',
        altText: 'Synchrony Bank',
      },
      {
        image: 'national-geographic',
        altText: 'National Geographic',
      },
      {
        image: 'abi',
        altText: 'Anheuser-Busch InBev',
      },
      {
        image: 'fox',
        altText: 'Fox Sports',
      },
      {
        image: 'general-electric',
        altText: 'General Electric',
      },
      {
        image: 'pepsico',
        altText: 'Pepsico',
      },
      {
        image: 'american-express',
        altText: 'American Express',
      },
      {
        image: 'johnson-johnson',
        altText: 'Johnson and Johnson',
      },
    ],
  } as ProductDesignClientSectionProps,

  // Design Culture Section
  designCultureSection: {
    title: 'Design Culture',
    culturePoints: [
      {
        image: 'scales',
        altText: 'Balanced Scale',
        title: 'Split design studio and project team',
        description:
          'Have an idea to run past your project team? Need design input to elevate your concept? No need to choose: you get a spot in both our Design Studio and project spaces, so you can collaborate with the people whose skills and experience will help sharpen yours the most.',
      },
      {
        image: 'ratio',
        altText: 'Decorative 1 to 4 ratio',
        title: 'Design to Developers',
      },
      {
        image: 'plane',
        altText: 'Airplane flying in the sky and a location pin',
        title: 'Paid Conferences',
      },
      {
        image: 'chat-bubbles',
        altText: 'Speech bubbles illustrate a conversation',
        title: 'Design Critiques',
      },
      {
        image: 'pencil-rule',
        altText: 'Pencil and ruler',
        title: 'Design Challenges',
      },
      {
        image: 'breakfast',
        altText: 'Hot coffee and bagel breakfast',
        title: 'Design Breakfast',
      },
      {
        image: 'target',
        altText: 'Target with an arrow in the bullseye',
        title: 'Team-Generated Yearly Goals',
      },
    ],
  } as DesignCultureProps,

  // Where we work section
  whereWeWorkSection: {
    title: 'Where We Work',
  } as WhereWeWorkProps,

  // Three Offices Section
  threeOfficesSection: {
    title: 'Three Offices',
    cvilleCaption: '21 Designers',
    durhamCaption: '15 Designers',
    columbusCaption: '8 Designers',
  } as ThreeOfficesProps,

  // WilloWtreeIsADestinationJobSection
  willowTreeIsADestinationJobSection: {
    title: 'WillowTree is a Destination Job',
    bodyParagraphs: [
      'Not from Charlottesville, Columbus, or Durham? Neither are 80% of our designers. Most of us moved here from other places to do the best work of our careers and make our homes in these charming, livable cities.',
    ],
  } as WillowTreeIsADestinationJobProps,

  demoReelSection: {
    title: 'Demo Reel',
  } as DemoReelProps,

  // Join The Team Section
  joinTheTeamSection: {
    title: 'Join the Team',
    bodyParagraphs: [
      'We are always looking for talented people — to help us grow, evolve and become better versions of ourselves. If this sounds compelling, get in touch.',
    ],
    findYourRoleTitleMobile: 'Find your role by location',
    findYourRoleTitleDesktop: 'Find your role in',
    durhamAddress: {
      url:
        'https://willowtreeapps.com/careers/jobs?category=Open&office=Durham&team=Product',
      street: '800 Taylor Street, Suite 300',
      city: 'Durham, NC 27702',
      country: 'USA',
    },
    cvilleAddress: {
      url:
        'https://willowtreeapps.com/careers/jobs?category=Open&office=Charlottesville&team=Product',
      street: '107 5th Street SE',
      city: 'Charlottesville, VA 22902',
      country: 'USA',
    },
    columbusAddress: {
      url:
        'https://www.willowtreeapps.com/careers/jobs?category=Open&office=Columbus&team=Product',
      street: '274 Marconi Blvd, Suite 300',
      city: 'Columbus, OH 43215',
      country: 'USA',
    },
  } as JoinTheTeamProps,
};
