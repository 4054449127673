import React from 'react';
import {Col} from '../Grid';

import componentStyles from './IconCard.module.scss';

import BrainIcon from '../../assets/images/product-designer/brain.svg';
import HandIcon from '../../assets/images/product-designer/hand.svg';
import LightBulbsIcon from '../../assets/images/product-designer/light-bulbs.svg';
import MagnifyingGlassIcon from '../../assets/images/product-designer/magnifying-glass.svg';
import PenIcon from '../../assets/images/product-designer/pen.svg';
import PlayIcon from '../../assets/images/product-designer/play.svg';
import VectorIcon from '../../assets/images/product-designer/vector.svg';
import WindowsIcon from '../../assets/images/product-designer/windows.svg';

export interface IconCardProps {
  animation: string;
  image: string;
}

const IconCard: React.SFC<IconCardProps> = ({image, animation}) => {
  const selectIcon = (image: string) => {
    switch (image) {
      case 'brain':
        return BrainIcon;
      case 'hand':
        return HandIcon;
      case 'light-bulbs':
        return LightBulbsIcon;
      case 'magnifying-glass':
        return MagnifyingGlassIcon;
      case 'pen':
        return PenIcon;
      case 'play':
        return PlayIcon;
      case 'vector':
        return VectorIcon;
      case 'windows':
        return WindowsIcon;
      default:
        return '';
    }
  };

  return (
    <Col
      wide={1}
      additionalClasses={componentStyles.container}
      animation={animation}
    >
      <img
        className={componentStyles.image}
        alt=""
        src={selectIcon(image)}
      ></img>
    </Col>
  );
};

export default IconCard;
