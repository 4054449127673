import React from 'react';
import {Col} from '../Grid';

import componentStyles from './ClientLogoCard.module.scss';

import ABILogo from '../../assets/images/client-logos/abi.png';
import AELogo from '../../assets/images/client-logos/american-express.svg';
import FoxLogo from '../../assets/images/client-logos/fox.png';
import GELogo from '../../assets/images/client-logos/general-electric.svg';
import JJLogo from '../../assets/images/client-logos/johnson-johnson.svg';
import NatGeoLogo from '../../assets/images/client-logos/national-geographic.png';
import PepsicoLogo from '../../assets/images/client-logos/pepsico.svg';
import SynchronyLogo from '../../assets/images/client-logos/synchrony.png';

export interface LogoCardProps {
  image: string;
  altText: string;
  animation: string;
}

const LogoCard: React.SFC<LogoCardProps> = ({image, altText, animation}) => {
  const selectLogo = (logo: string) => {
    switch (logo) {
      case 'abi':
        return ABILogo;
      case 'american-express':
        return AELogo;
      case 'fox':
        return FoxLogo;
      case 'general-electric':
        return GELogo;
      case 'johnson-johnson':
        return JJLogo;
      case 'national-geographic':
        return NatGeoLogo;
      case 'pepsico':
        return PepsicoLogo;
      case 'synchrony':
        return SynchronyLogo;
      default:
        return '';
    }
  };

  return (
    <Col additionalClasses={componentStyles.container} animation={animation}>
      <img
        className={componentStyles.image}
        alt={altText}
        src={selectLogo(image)}
      ></img>
    </Col>
  );
};

export default LogoCard;
