import React from 'react';

import Row, {Col} from '../../components/Grid';
import LogoCard, {LogoCardProps} from '../../components/ClientLogoCard';

import typographyStyles from '../../styles/globals/typography.module.scss';
// import spacingStyles from '../../styles/globals/spacing.module.scss';

export interface ProductDesignClientSectionProps {
  title: string;
  clients: LogoCardProps[];
}

const ProductDesignClientSection: React.SFC<
  ProductDesignClientSectionProps
> = ({title, clients}) => {
  const getAnimation = (i: number) => {
    if (i % 2 === 0) {
      return 'fade-down';
    } else {
      return 'fade-up';
    }
  };

  return (
    <Col animation="fade-up" fullWidth>
      <Row>
        <Col smallOffset={1} wideOffset={1}>
          <h3 className={typographyStyles[`heading--four`]}>{title}</h3>
        </Col>
      </Row>
      <Row>
        {clients.map((client, index) => (
          <LogoCard
            key={client.image}
            image={client.image}
            altText={client.altText}
            animation={getAnimation(index)}
          />
        ))}
      </Row>
    </Col>
  );
};

export default ProductDesignClientSection;
