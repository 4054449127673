import React from 'react';
import cn from 'classnames';

import FlexContainer from '../FlexContainer';

import componentStyles from './EmployeeCallout.module.scss';
import typographyStyles from '../../styles/globals/typography.module.scss';

export interface EmployeeCalloutProps {
  image: string;
  altText: string;
  name: string;
  position: string;
  small?: boolean;
}

const EmployeeCallout: React.SFC<EmployeeCalloutProps> = ({
  image,
  altText,
  name,
  position,
  small,
}) => {
  let nameClass, containerClass, employeeName;

  if (small) {
    nameClass = 'subheading--two';
    containerClass = 'small';
    employeeName = `- ${name}`;
  } else {
    nameClass = 'heading--six';
    containerClass = 'regular';
    employeeName = name;
  }

  const nameClasses = cn(componentStyles.name, {
    [typographyStyles[`${nameClass}`]]: nameClass,
  });

  return (
    <figure>
      <FlexContainer alignItems="center" justify-content="center" noWrap>
        <img
          className={componentStyles[`${containerClass}`]}
          src={process.env.PUBLIC_URL + `/images/employees/${image}.jpg`}
          alt={altText}
        />
        <figcaption className={componentStyles.caption}>
          <p className={nameClasses}>{employeeName}</p>
          <p
            className={cn(
              componentStyles.position,
              typographyStyles['subheading--three']
            )}
          >
            {position}
          </p>
        </figcaption>
      </FlexContainer>
    </figure>
  );
};

export default EmployeeCallout;
