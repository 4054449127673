import React from 'react';
import cn from 'classnames';

import Vimeo from '../../components/Vimeo';
import Row, {Col} from '../../components/Grid';

import sectionStyles from './DemoReel.module.scss';
import typographyStyles from '../../styles/globals/typography.module.scss';

export interface DemoReelProps {
  title: string;
}

const DemoReelSection: React.SFC<DemoReelProps> = ({title}) => {
  return (
    <Col additionalClasses={sectionStyles.container}>
      <Row mobilePadding="l" desktopPadding="l">
        <Col
          offset
          small={8}
          smallOffset={2}
          medium={7}
          largeOffset={1}
          desktop={6}
          wide={5}
          wideOffset={1}
        >
          <Row section>
            <Col>
              <h3
                className={cn(
                  typographyStyles['heading--four'],
                  sectionStyles.title
                )}
              >
                {title}
              </h3>
            </Col>

            <Col
              smallOffset={1}
              small={6}
              wideOffset={1}
              additionalClasses={sectionStyles.text}
            >
              <p>
                We care deeply about our partnerships, so some of our client
                work is confidential. Please contact{' '}
                <a href="mailto:erika.cober@willowtreeapps.com">
                  erika.cober@willowtreeapps.com
                </a>{' '}
                to request access.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row additionalClasses={sectionStyles.video}>
        <Col>
          <Vimeo />
        </Col>
      </Row>
    </Col>
  );
};

export default DemoReelSection;
