import React from 'react';

import Lottie from '../../components/LottieAnimation';
import Row, {Col} from '../../components/Grid';

import sectionStyles from './DesignDNA.module.scss';
import typographyStyles from '../../styles/globals/typography.module.scss';

import wtLogo from '../../assets/images/WT_Logo_Black.svg';

export interface DesignDNAProps {
  willowTreeLogoAlt: string;
  caption: string;
}

const DesignDNASection: React.SFC<DesignDNAProps> = ({
  willowTreeLogoAlt,
  caption,
}) => {
  return (
    <Col>
      <a className={sectionStyles.logo} href="http://www.willowtreeapps.com">
        <img src={wtLogo} alt={willowTreeLogoAlt} />
      </a>

      <Row>
        <Col
          small={7}
          desktop={6}
          wide={6}
          additionalClasses={sectionStyles.container}
        >
          <h1 hidden>Design DNA</h1>
          <Lottie lottie="DesignDNA" />
        </Col>
        <Col additionalClasses={sectionStyles.subheading}>
          <p className={typographyStyles['subheading--one']}>{caption}</p>
        </Col>
      </Row>
    </Col>
  );
};

export default DesignDNASection;
