import React from 'react';
import cn from 'classnames';

import Row, {Col} from '../Grid';

import componentStyles from './PrincipleBlurb.module.scss';
import typographyStyles from '../../styles/globals/typography.module.scss';

export interface PrincipleBlurbProps {
  number: number;
  caption: string;
}

const PrincipleBlurb: React.SFC<PrincipleBlurbProps> = ({number, caption}) => {
  return (
    <Row additionalClasses={componentStyles.blurb}>
      <Col small={1} smallOffset={1} additionalClasses={componentStyles.left}>
        <div className={componentStyles['numbered-blurb']}>
          <span
            className={cn(
              componentStyles.number,
              typographyStyles['heading--eight'],
              typographyStyles.white
            )}
          >{`0${number}`}</span>
        </div>
      </Col>

      <Col medium={8} additionalClasses={componentStyles.right}>
        <p
          className={cn([
            componentStyles.principle,
            typographyStyles['list--one'],
          ])}
        >
          {caption}
        </p>
      </Col>
    </Row>
  );
};

export default PrincipleBlurb;
