import React from 'react';
import cn from 'classnames';

import BeliefCard, {BeliefCardProps} from '../../components/BeliefCard';
import {Colors} from '../../utils/typeDefinitions';
import Row, {Col} from '../../components/Grid';

import sectionStyles from './Vision.module.scss';
import typographyStyles from '../../styles/globals/typography.module.scss';

interface BeliefProps {
  title: string;
  imageCards: BeliefCardProps[];
}

export interface VisionProps {
  title: string;
  titleColor: Colors;
  bodyParagraphs: string[];
  beliefs: BeliefProps;
}

const VisionSection: React.SFC<VisionProps> = ({
  title,
  titleColor,
  bodyParagraphs,
  beliefs,
}) => {
  return (
    <Col animation="fade-up">
      <h2
        className={cn(
          typographyStyles['heading--two'],
          typographyStyles[`${titleColor}`]
        )}
      >
        {title}
      </h2>

      <Row>
        <Col offset small={7} medium={6} desktop={3} wide={3} wideOffset={1}>
          {bodyParagraphs.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </Col>

        <Col
          small={8}
          large={5}
          largeOffset={3}
          desktop={3}
          desktopOffset={1}
          wide={3}
          wideOffset={1}
          additionalClasses={sectionStyles['swirl-gap']}
          section
        >
          <h4
            className={cn(
              typographyStyles['heading--five'],
              sectionStyles.header
            )}
          >
            {beliefs.title}
          </h4>
          <ul className={sectionStyles.list}>
            {beliefs.imageCards.map((card, index) => (
              <li
                key={card.belief}
                className={sectionStyles.item}
                data-aos="fade-left"
                data-aos-delay={100 * index}
              >
                <BeliefCard
                  belief={card.belief}
                  rightImage={index % 2 === 0}
                  caption={card.caption}
                  backgroundColor={card.backgroundColor}
                />
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </Col>
  );
};

export default VisionSection;
