import React from 'react';
import cn from 'classnames';

import typographyStyles from '../../styles/globals/typography.module.scss';
import spacingStyles from '../../styles/globals/spacing.module.scss';
import EmployeeCallout, {EmployeeCalloutProps} from '../EmployeeCallout';

export interface BlockQuoteProps {
  quote: string;
  author: EmployeeCalloutProps;
}

const Blockquote: React.SFC<BlockQuoteProps> = ({quote, author}) => {
  return (
    <>
      <p
        className={cn(
          typographyStyles.midBlue,
          typographyStyles.quote,
          spacingStyles['padding--s']
        )}
      >
        {quote}
      </p>

      <EmployeeCallout
        small
        image={author.image}
        altText={author.altText}
        name={author.name}
        position={author.position}
      />
    </>
  );
};

export default Blockquote;
