import React from 'react';

import HeadlineDescription from '../../components/HeadlineDescription';
import IconCard, {IconCardProps} from '../../components/IconCard';
import Row, {Col} from '../../components/Grid';

import sectionStyles from './WhatIsAProductDesigner.module.scss';

export interface WhatIsAProductDesignerProps {
  title: string;
  bodyParagraphs: string[];
  graphic: IconCardProps[];
}

const WhatIsAProductDesignerSection: React.SFC<WhatIsAProductDesignerProps> = ({
  title,
  bodyParagraphs,
  graphic,
}) => {
  const getAnimation = (i: number) => {
    if (i % 2 === 0) {
      return 'fade-down';
    } else {
      return 'fade-up';
    }
  };

  return (
    <Col animation="fade-up">
      <Row mobilePadding="section-s" desktopPadding="l" section>
        <Col small={7} smallOffset={1} desktop={6} wide={5} wideOffset={1}>
          <HeadlineDescription title={title} description={bodyParagraphs} />
        </Col>
      </Row>

      <Row additionalClasses={sectionStyles.container} section>
        {graphic.map((icon, index) => (
          <IconCard
            key={icon.image}
            image={icon.image}
            animation={getAnimation(index)}
          ></IconCard>
        ))}
      </Row>
    </Col>
  );
};

export default WhatIsAProductDesignerSection;
